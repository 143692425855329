<template>
  <v-app-bar
    app
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="toggleDrawer"
    />

    <v-container class="mx-auto py-0">
      <v-row align="center">
        <v-img
          :src="require('@/assets/logos/logoDenominacionOnly.png')"
          class="mr-5 header-image hidden-sm-and-down"
          contain
          height="150"
          width="150"
          max-width="150"
          @click="$vuetify.goTo(0)"
        />

        <v-img
          :src="require('@/assets/logos/logoDenominacionSingleLine.png')"
          class="mr-5 header-image hidden-md-and-up"
          contain
          height="150"
          width="300"
          max-width="300"
          @click="$vuetify.goTo(0)"
        />

        <v-btn
          v-for="(link, i) in links"
          :key="i"
          v-bind="link"
          class="hidden-sm-and-down"
          text
          dark
          @click="onClick($event, link)"
        >
          {{ link.text }}
        </v-btn>

        <v-btn
          class="hidden-sm-and-down"
          text
          dark
          @click="triggerModal()"
        >
          {{ content.contact }}
        </v-btn>

        <v-spacer />
        <div class="language-btn-div hidden-sm-and-down">
          <v-icon class="icon-language ma-2">
            mdi-translate
          </v-icon>
          <a
            class="language-btn"
            @click="setLang('es')"
          >ES</a>|<a
            class="language-btn"
            @click="setLang('en')"
          >EN</a>|<a
            class="language-btn"
            @click="setLang('fr')"
          >FR</a>
        </div>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
  // Utilities
  import {
    mapGetters,
    mapMutations,
  } from 'vuex'
  import { languageMethods } from '../../shared'

  export default {
    name: 'CoreAppBar',
    mixins: [languageMethods],
    computed: {
      ...mapGetters(['links']),
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].menuHeader
      },
    },
    methods: {
      ...mapMutations(['toggleDrawer', 'showModal']),
      onClick (e, item) {
        e.stopPropagation()
        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },
      triggerModal() {
        this.showModal()
      },
      setLang (lang) {
        this.setCurrentLanguage(lang)
      },
    },
  }
</script>

<style scoped>
  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #3F5465;
    /* background-color: #4E7A7F; */
  }
  a span{
    color: #ffffff;
  }
  a:hover {
    background-color: #e2eef1;
    color: #3F5465;
  }
  a:active {
    background-color: #F7D795;
    color: #3F5465;
  }
  .white-nav {
    color: #ffffff;
  }
  .header-image {
    cursor: pointer;
  }
  .language-btn-div {
    color: #e2eef1;
  }
  .icon-language {
    color: #e2eef1 !important;
  }
  .language-btn {
    padding: 5px;
    border-radius: 5px;
  }
</style>
